import React, { useState, useEffect, useRef } from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import HeaderLogo from "../../components/headerLogo/headerLogo"
import HeaderBack from "../../components/headerBack/headerBack"
import HeaderCart from "../../components/headerCart/headerCart"

import ProductV2 from "../../components/product/productV2"

function ProductBustierDress() {

    const shopifyProductId = '6121670672551'
    const base64ProductId = Buffer.from('gid://shopify/Product/' + shopifyProductId).toString('base64')

    const sizes = {
        "Length": [
            "",
            "32 3/4 / 83.5",
            "33 / 84",
            "33 1/4 / 84.5",
            "33 1/2 / 85"
        ],
        "Breast": [
            "",
            "32 1/4 / 82",
            "33 3/4 / 86",
            "35 1/2 / 90",
            "37 / 94"
        ],
        "Waist": [
            "",
            "25 / 63.5",
            "26 / 66",
            "27 / 68.5",
            "28 / 71.1"
        ],
        "Hip": [
            "",
            "32 1/4 / 82",
            "33 3/4 / 86",
            "35 1/2 / 90",
            "37 / 94"
        ],
        "Slit": [
            "",
            "13 3/4 / 35"
        ]
    }

    return (
        <Layout>
            <SEO title="Shop - Bag" />

            <ProductV2 productId={base64ProductId} sizes={sizes} />
        </Layout>
    )

} export default ProductBustierDress